<i18n lang="yaml">
pt:
  trigger: 'Gatilho'
  explainTrigger: 'Um gatilho é um evento que inicia a execução da sua automação'
  explainSchema: "Defina os parâmetros que sua automação espera receber"
  explainOutputSchema: "Defina os parâmetros que sua automação irá retornar"
  fieldsToWatch: 'Campos para observar'
  selectSheet: 'Para qual base de dados essa ação estará disponível?'
  availableForAllViews: 'Essa ação será executada em todas as páginas da base de dados'
  sync: 'Aguardará a execução de todas as ações'
  syncHint: 'Quando o gatilho "{triggerName}" iniciar a execução da automação, o sistema aguardará até que todas as ações sejam concluídas antes de continuar. Ações demoradas podem fazer o usuário esperar mais do que o necessário.'
  forceAsync: 'Será executado de forma assíncrona quando chamado'
  workflowWithEmptyGlobalParametersWarning: "Existem parâmetros globais sem valor definido, a automação pode não funcionar corretamente. Por favor, preencha-os antes de continuar: {globalParametersNames}."
  watchAllFields: 'Observar todos os campos'
  cantWatchCalculatedField: 'Este campo é calculado por uma fórmula e não é possível observá-lo'
  cantWatchLinkField: 'Este campo é do tipo "Buscar campo" e não é possível observá-lo'
  cantWatchCalculatedLinkField: 'Este campo é do tipo "Buscar campo", sendo calculado por uma fórmula e não é possível observá-lo'
  allFieldsPresentAndFuture: 'Todos os campos da base de dados serão observados, inclusive campos criados no futuro. Note que campos do tipo "Fórmula" e "Buscar campo" não poderão ser observados.'
  allPresentFields: 'Todos os campos presentes foram marcados. Se novos campos forem criados na base de dados, eles não serão observados. Se deseja observar todos os campos, inclusive os futuramente criados, selecione a opção "Todos os campos presentes e futuros".'
  tooltip:
    runWorkflow: 'Executar automação'
  notification:
    runningWorkflow: 'Automação iniciada com sucesso'
  errors:
    workflow_trigger:
      workflow_trigger_workflow_not_published:
        'Automação precisa estar publicada'
en:
  trigger: 'Trigger'
  explainTrigger: 'Trigger is an event that starts the execution of your automation'
  explainSchema: "Define the parameters your automation expects to receive"
  explainOutputSchema: "Define the parameters your automation will return"
  fieldsToWatch: 'Fields to watch'
  selectSheet: 'For which sheet will this automation be available?'
  availableForAllViews: 'This automation will be executed in all pages of the sheet'
  sync: 'Will wait for all actions to be executed'
  syncHint: 'When the trigger "{triggerName}" starts the automation execution, the system will wait until all actions are completed before continuing. Long actions can make the user wait longer than necessary.'
  forceAsync: 'Will be executed asynchronously when called'
  workflowWithEmptyGlobalParametersWarning: "There are global parameters without a defined value, the automation may not work correctly. Please fill them in before continuing: {globalParametersNames}."
  watchAllFields: 'Watch all fields'
  cantWatchCalculatedField: 'This field is calculated by a formula and cannot be watched'
  cantWatchLinkField: 'This field is a "Lookup field" and cannot be watched'
  cantWatchCalculatedLinkField: 'This field is a "Lookup field", being calculated by a formula and cannot be watched'
  allFieldsPresentAndFuture: 'All fields in the sheet will be watched, including fields created in the future. Note that fields of type "Formula" and "Lookup field" cannot be watched.'
  allPresentFields: 'All present fields have been marked. If new fields are created in the sheet, they will not be watched. If you want to watch all fields, including those created in the future, select the option "All present and future fields".'
  tooltip:
    runWorkflow: 'Run automation'
  notification:
    runningWorkflow: 'Automation started successfully'
  errors:
    workflow_trigger:
      workflow_trigger_workflow_not_published:
        'Automation needs to be published'
</i18n>

<template>
  <div class="trigger-editor">
    <v-card
      elevation="0"
      class="trigger-editor__editor-card"
    >
      <v-card-item>
        <v-card-title class="text-h6">
          <deck-icon
            name="signal-stream"
            class="mr-2"
          />
          <span>{{ t('trigger') }}</span>
          <div
            v-if="canRunWorkflowOnStudio"
            class="flex-grow-1 d-flex justify-end"
          >
            <deck-button
              :text="t('tooltip.runWorkflow')"
              color="controls"
              kind="secondary"
              icon="play"
              :loading="runningWorkflow"
              is-ready
              @click="runWorkflow"
            />
          </div>
        </v-card-title>

        <v-card-subtitle>
          {{ t('explainTrigger') }}
        </v-card-subtitle>
      </v-card-item>

      <v-card-text
        v-if="Object.keys(currentTrigger).length > 0"
        class="trigger-editor__editor-card-content text--primary"
      >
        <workflow-trigger-type-select
          :model-value="currentTrigger.trigger_type"
          disabled
        />

        <sheet-select
          v-if="triggerConfig.sheetBased"
          v-model="targetSheetId"
          :hint="t('availableForAllViews')"
          :label="t('selectSheet')"
          :disabled="Boolean(targetSheetId)"
        />

        <workflow-trigger-views-select
          v-if="triggerConfig.viewBased"
        />

        <template
          v-if="isFeatureEnabled('syncTriggerEditable') && ['UserRequest', 'FormSubmit', 'UserViewButtonAction'].includes(currentTrigger.trigger_type)"
        >
          <!-- Add deck-switch to enable/disable sync -->
          <deck-switch
            v-model="sync"
            :label="t('sync')"
          />
        </template>
        <template v-else>
          <div
            v-if="currentTrigger.sync"
            class="d-flex align-center mt-4"
          >
            <p class="d-flex align-baseline mb-0">
              <deck-icon
                name="stopwatch"
                class="mr-2"
              />
              {{ t('sync') }}
            </p>
            <deck-hinter
              :text="t('syncHint', { triggerName })"
              class="ml-2"
            />
          </div>
        </template>

        <deck-switch
          v-if="['WorkflowRequest'].includes(currentTrigger.trigger_type)"
          v-model="forceAsync"
          :label="t('forceAsync')"
        />

        <WebhookTriggerEditor
          v-if="currentTrigger.trigger_type === 'Webhook'"
          v-test-id="'webhook-trigger-editor'"
        />

        <SchedulingSelector
          v-if="isSchedulingTrigger"
          v-test-id="'scheduling-selector'"
        />

        <template v-if="canEditSchema">
          <schema-editor
            v-model="schema"
            v-test-id="'trigger-schema-editor'"
            :title="t('explainSchema')"
          />

          <schema-editor
            v-model="outputSchema"
            v-test-id="'trigger-output-schema-editor'"
            :title="t('explainOutputSchema')"
            is-selecting
          />
        </template>

        <deck-alert
          v-if="showGlobalParametersWithEmptyValueWarning"
          kind="warning"
          :text="t('workflowWithEmptyGlobalParametersWarning', { globalParametersNames: emptyGlobalParametersNames.join(', ') })"
        />

        <template v-if="currentTrigger.trigger_type === 'RecordUpdated' && targetSheetId">
          <FieldSelect
            v-model="fieldsToWatch"
            :fields="fieldsOption"
            :label="t('fieldsToWatch')"
            :disabled-matcher="disabledMatcher"
            enable-every-field-item
            multiple
            required
            class="px-0"
          />
          <deck-alert
            v-if="_isEqual(fieldsToWatch, ['every'])"
            kind="info"
            :text="t('allFieldsPresentAndFuture')"
          />
          <deck-alert
            v-else-if="selectableFieldsLength === fieldsToWatch.length"
            kind="info"
            :text="t('allPresentFields')"
          />
        </template>
      </v-card-text>
    </v-card>

    <StepCreator
      v-if="canShowStepsCreator"
      v-test-id="'step-creator'"
      :step="currentWorkflow.sorted_steps[0]"
      is-trigger
      is-root
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from '~/assets/javascript/modules/vuex';
import SchedulingSelector from '~/components/studio/workflows/trigger-editor/scheduling-selector';
import { TRIGGER_TYPES, TRIGGER_CONFIG } from '~/assets/javascript/constants/types';
import WebhookTriggerEditor from '~/components/studio/workflows/trigger-editor/webhook-editor';
import StepCreator from '~/components/studio/workflows/StepCreator';
import WorkflowTriggerTypeSelect from '~/components/workflow-trigger-type-select';
import SheetSelect from '~/components/sheet-select';
import WorkflowTriggerViewsSelect from '~/components/studio/workflows/trigger-editor/views-select';
import { debounce } from 'lodash';

export default {
  name: 'TriggerEditor',
  components: {
    FieldSelect: defineAsyncComponent(() => import('~/components/field-select')),
    SchemaEditor: defineAsyncComponent(() => import('~/components/studio/workflows/trigger-editor/schema-editor')),
    SchedulingSelector,
    SheetSelect,
    StepCreator,
    WebhookTriggerEditor,
    WorkflowTriggerTypeSelect,
    WorkflowTriggerViewsSelect,
  },
  setup() {
    return {
      t: useI18n().t,
      ...mapState('workflows', ['workflowStepsEntityDependencies', 'loadingWorkflowSteps']),
      ...mapState('globalParameters', ['globalParameters']),
      ...mapGetters('workflows', ['currentTrigger', 'currentWorkflow', 'triggerHasDynamicSchema']),
      ...mapGetters('features', ['isFeatureEnabled']),
      ...mapActions('workflows', ['updateTrigger', 'loadWorkflowStepsEntityDependencies']),
      ...mapActions('globalParameters', ['fetchGlobalParameters']),
    };
  },
  data() {
    return {
      fieldsToWatch: [],
      runningWorkflow: false,
      debouncedUpdateTrigger: debounce(this.updateTrigger, 1000),
    };
  },
  computed: {
    canShowStepsCreator() {
      if (this.loadingWorkflowSteps) return false;
      if (_isEmpty(this.currentWorkflow)) return false;
      if (this.currentWorkflow.trigger?.trigger_type === 'UserViewButtonAction') return true;

      const hasSheet = Boolean(this.targetSheetId);
      const needsSheet = this.triggerConfig.sheetBased || this.triggerConfig.viewBased;

      if (needsSheet) return hasSheet;

      return true;
    },
    canRunWorkflowOnStudio() {
      return this.currentTrigger.trigger_type === 'Schedule';
    },
    triggerName() {
      return TRIGGER_TYPES[this.currentTrigger.trigger_type].locales[this.$i18n.locale];
    },
    triggerConfig() {
      return TRIGGER_CONFIG[this.currentTrigger.trigger_type] || {};
    },
    fieldsOption() {
      return this.currentTrigger.fields;
    },
    canEditSchema() {
      // TODO: Allow schema editor for Webhook and then replace the params_map
      // for the input schema
      return this.triggerHasDynamicSchema && this.currentTrigger.trigger_type !== 'Webhook';
    },
    schema: {
      get() {
        return _cloneDeep(this.currentTrigger.schema);
      },
      set(value) {
        this.debouncedUpdateTrigger({
          schema: value,
          workflowTriggerId: this.currentTrigger.id,
        });
      },
    },
    outputSchema: {
      get() {
        return _cloneDeep(this.currentTrigger.output_schema);
      },
      set(value) {
        this.debouncedUpdateTrigger({
          outputSchema: value,
          workflowTriggerId: this.currentTrigger.id,
        });
      },
    },
    targetSheetId: {
      get() {
        return this.currentTrigger.sheet_id
          || this.currentTrigger.views?.[0]?.sheet_id;
      },
      set(value) {
        this.updateTrigger({
          sheetId: value,
          workflowTriggerId: this.currentTrigger.id,
        });
      },
    },
    sync: {
      get() {
        return this.currentTrigger.sync;
      },
      set(value) {
        this.updateTrigger({
          sync: value,
          workflowTriggerId: this.currentTrigger.id,
        });
      },
    },
    forceAsync: {
      get() {
        return this.currentTrigger.options.force_async || false;
      },
      set(value) {
        this.updateTrigger({
          forceAsync: value,
          workflowTriggerId: this.currentTrigger.id,
        });
      },
    },
    workflowGlobalParametersDependencies() {
      return Object.values(this.workflowStepsEntityDependencies).flat().filter(
        dependency => dependency.target_type === 'GlobalParameter',
      );
    },
    showGlobalParametersWithEmptyValueWarning() {
      return !_isEmpty(this.emptyGlobalParametersNames);
    },
    emptyGlobalParametersNames() {
      const names = [];

      this.workflowGlobalParametersDependencies.forEach((element) => {
        const dependency = this.globalParameters.find(
          globalParameter => globalParameter.id === element.target_id && !globalParameter.value,
        );

        if (dependency) {
          names.push(dependency.name);
        }
      });

      return names;
    },
    selectableFieldsLength() {
      return this.fieldsOption?.filter((field) => {
        const { disabled } = this.disabledMatcher(field);
        return !disabled;
      })?.length;
    },
    isSchedulingTrigger() {
      return ['Schedule', 'ScheduleLegacy'].includes(this.currentTrigger.trigger_type);
    },
  },
  watch: {
    fieldsToWatch: {
      handler(value) {
        const options = { ...this.currentTrigger.options };

        if (_isEqual(value, ['every'])) {
          delete options.field_ids;
        } else {
          options.field_ids = value;
        }

        if (_isEqual(options, this.currentTrigger.options)) return;

        this.debouncedUpdateTrigger({
          workflowTriggerId: this.currentTrigger.id,
          options,
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.loadWorkflowStepsEntityDependencies();
    this.fetchGlobalParameters();
    this.fieldsToWatch = this.currentTrigger.options?.field_ids || ['every'];
    this.watchAllFields = !this.fieldsToWatch.length;
  },
  methods: {
    disabledMatcher(field) {
      let disabled = false;
      let disabledHint = '';

      if (field.type === 'Lookup') {
        disabled = true;
        disabledHint = this.t('cantWatchLinkField');
      }

      if (field.calculated === true || field.type === 'Formula') {
        disabled = true;
        disabledHint = this.t('cantWatchCalculatedField');

        if (field.calculation_type === 'lookup') {
          disabledHint = this.t('cantWatchCalculatedLinkField');
        }
      }

      return { disabled, disabledHint };
    },
    async runWorkflow() {
      this.runningWorkflow = true;

      try {
        const params = {
          workflow_trigger_id: this.currentTrigger.id,
        };

        await this.$apiClient.workflows.executions.test.create(params);
        this.$notifier.showMessage({ content: this.t('notification.runningWorkflow'), color: 'info' });
      } catch (error) {
        this.$errorRescue(this, error, 'runWorkflow');
      } finally {
        this.runningWorkflow = false;
      }
    },
  },
};
</script>

<style lang="scss">
.trigger-editor__editor-card-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>
