<i18n lang="yaml">
pt:
  defaultDataSourceLabel: 'Base de dados'
  hint: 'Só é possível selecionar páginas com associação à mesma base de dados'
  selectMoreThanOneViewDisabledHint: 'Esta automação possui ações com a opção de "Alteração ao executar" habilitada. Para habilitar a seleção de mais de uma página, desabilite esta opção em todas as ações.'
  viewsSelectLabel: 'Páginas para iniciar a automação'
  userViewButtonActionHint: 'Esta automação é acionada por um botão de ação adicionado na página. A seleção de páginas não é permitida.'
en:
  defaultDataSourceLabel: 'Sheet'
  hint: 'You can only select pages associated with the same sheet'
  selectMoreThanOneViewDisabledHint: 'This automation has actions with the "Override at runtime" option enabled. To enable the selection of more than one page, disable this option in all actions.'
  viewsSelectLabel: 'Pages to trigger the automation'
  userViewButtonActionHint: 'This automation is triggered by an action button added to the page. Page selection is not allowed.'
</i18n>
<template>
  <deck-select
    ref="select"
    :model-value="value"
    :label="t('viewsSelectLabel')"
    :items="items"
    :loading="loading"
    :hint="viewDisabledHint"
    :disabled="viewsSelectDisabled"
    required
    multiple
    select-all-disabled
    scroll-on-open
    @update:model-value="onViewsChange"
  />
</template>
<script>
import { mapState, mapGetters, mapActions } from '~/assets/javascript/modules/vuex';
import { TRIGGER_CONFIG, VIEW_TYPES } from '~/assets/javascript/constants';
import DeckSelect from '~/deck/select';

export default {
  name: 'WorkflowTriggerViewsSelect',
  components: {
    DeckSelect,
  },
  setup() {
    return {
      t: useI18n().t,
      ...mapState('builderView', ['views']),
      ...mapState('viewOptions', { sheets: 'sheetsCache' }),
      ...mapState('workflows', ['loadingWorkflowSteps']),
      ...mapGetters('workflows', ['currentTrigger', 'currentWorkflow']),
      ...mapActions('workflows', ['updateCurrentTriggerViews']),
    };
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    triggerConfig() {
      return TRIGGER_CONFIG[this.currentTrigger.trigger_type];
    },
    groupedViewsBySheet() {
      return this.views
        .filter(view => this.triggerConfig.viewTypes.includes(view.page_type))
        .reduce((acc, view) => {
          if (!acc[view.sheet_id]) {
            acc[view.sheet_id] = [];
          }

          acc[view.sheet_id].push(view);
          return acc;
        }, {});
    },
    items() {
      // Transform each group into the specified format
      return Object.entries(this.groupedViewsBySheet).map(
        ([sheetId, views]) => this.formatSheetViewsGroupItem(sheetId, views),
      );
    },
    value() {
      return this.currentTrigger.views.map(view => view.id);
    },
    filterBySheet() {
      return Boolean(this.value.length);
    },
    targetSheetId() {
      return this.currentTrigger.views[0]?.sheet_id;
    },
    clearViewsDisabled() {
      return this.currentWorkflow.sorted_steps.length > 0;
    },
    viewsSelectDisabled() {
      if (this.loadingWorkflowSteps || this.currentTrigger.trigger_type === 'UserViewButtonAction') return true;

      return this.currentTrigger.views.length === 1
        && (
          Object.values(this.currentWorkflow.stepsById).some(step => !_isEmpty(step.runtime_override_schema))
          || this.currentTrigger.trigger_type === 'ScheduleLegacy'
          // We do not want to allow changing views selection for scheduled triggers with frequency every 5 minutes
          || this.currentTrigger.scheduling?.frequency === 'every_five_minutes'
        );
    },
    viewDisabledHint() {
      if (this.currentTrigger.trigger_type === 'UserViewButtonAction') {
        return this.t('userViewButtonActionHint');
      }

      return this.t(this.viewsSelectDisabled ? 'selectMoreThanOneViewDisabledHint' : 'hint');
    },
  },
  methods: {
    findSheetById(sheetId) {
      return this.sheets.find(sheet => sheet.id === sheetId);
    },
    formatSheetViewsGroupItem(sheetId, views) {
      return {
        label: this.findSheetById(sheetId)?.name || this.t('defaultDataSourceLabel'),
        icon: 'database',
        items: views.map(this.formatViewItem),
      };
    },
    formatViewItem(view) {
      const isDisabled = (this.filterBySheet && view.sheet_id !== this.targetSheetId) || this.viewsSelectDisabled;
      return {
        text: view.page_name,
        value: view.id,
        icon: VIEW_TYPES[view.page_type].rawIcon,
        disabled: isDisabled,
        disabledHint: isDisabled ? this.viewDisabledHint : undefined,
      };
    },
    async onViewsChange(views) {
      // Once workflow has some step, it's not possible to clear the views
      // because it could break the workflow execution for missing references
      if (views.length === 0 && this.clearViewsDisabled) return;

      try {
        this.loading = true;

        await this.updateCurrentTriggerViews({ views: this.views.filter(({ id }) => views.includes(id)) });
      } catch (error) {
        this.$errorRescue(this, error, 'workflowTriggerViewsSelect');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
